(function (a, b) {
    typeof define === 'function' && define.amd ? define(['jquery'], b) : a.b = b(jQuery);
})(typeof window !== undefined ? window : this, function ($) {
    if (!$) return;
    var proto = null;
    var insArray = [];

    $.fn.marquee = function (settings) {
        var items = this;
        var count = this.length;

        if (count <= 0) return false;
        for (var i = 0; i < count; i++) {
            var item = items.eq(i);
            var instance = new Marquee(item, settings);
            instance.initial();
            insArray.push(instance)
        }
    }

    $.fn.marquee.paused = function () {
        for (var i = 0; i < insArray.length; i++) {
            var item = insArray[i];
            item.haltPlay();
        }
    }

    $.fn.marquee.reInit = function (i) {
        if (i !== undefined && typeof i === 'number') {
            insArray[i].reInit()
        }
    }

    var Marquee = function ($element, settings) {
        this.node = { elem: $element };
        this.index = 0;
        this.count = 0;
        this.timer = null;
        this.params = $.extend({}, this.options, settings);
        this.wrapLong = 0;
        this.moveLong = 0;
        this.itemLong = 0;
        this.listLong = 0;
        this.itemSpace = 0;
        this.initScroll = 0;
        this.isAcross = (this.params.direction === 'left' || this.params.direction === 'right');
    };

    proto = Marquee.prototype;
    proto.options = {
        duration: 500, // 滚动项的内边距 默认为0，表示没有
        interval: 4500, // 间隔时间
        flexible: false,
        autoplay: true, // 是否自动播放
        circular: true, // 是否无限循环
        isRoller: false, // 是否开启滚动
        hoverStop: true, // 是否鼠标悬浮暂停播放
        hasNumber: false, // 是否显示带数字标示
        elemSwidth: false, // 是否本身点击切换

        slideCol: 4, // 默认显示数量为1
        slideRow: 1, // 默认显示行数
        slideCount: 1, // 默认移动数量为1
        slideSpace: 10, // 默认间隔空隙 number /  / %

        direction: 'left',
        dotsEvent: 'click',
        slideView: '.marquee-view',
        slideList: '.marquee-list',
        slideItem: '.marquee-item',
        slidePrev: '.marquee-prev',
        slideNext: '.marquee-next',
        slideDots: '.marquee-dots',
        slideSpot: '.marquee-spot',
        itemActive: 'marquee-item-active',
        spotActive: 'marquee-spot-active',
        beforeTranslate: function () {},
        afterTranslate: function () {},
        onResize: function () {},
        initial: function () {},
    };
    proto.initial = function () {
        var node = this.node;
        var params = this.params;
        var viewCount = params.slideCol * params.slideRow;

        node.view = node.elem.find(params.slideView);
        node.list = node.view.find(params.slideList);
        node.item = node.list.find(params.slideItem);
        node.prev = node.elem.find(params.slidePrev);
        node.next = node.elem.find(params.slideNext);
        node.dots = node.elem.find(params.slideDots);

        this.count = node.item.length;
        this.count >= viewCount ? this.setNode() : this.setSize();
        params.flexible && this.resized();
    };
    proto.reInit = function () {
        this.setSize();
        this.autoplay();
    }

    proto.setNode = function (fn) {
        var node = this.node;
        var params = this.params;
        var viewGroup = '<div class="marquee-group"></div>';
        var slideCount = params.slideCount * params.slideRow;

        // 标试点
        if (node.dots.length > 0 && !params.isRoller) {
            var spot = '';
            var nums = Math.ceil(this.count / slideCount);
            for (var i = 0; i < nums; i++) {
                spot += '<span class="marquee-spot">' + (params.hasNumber ? i + 1 : '') + '</span>';
            }
            node.spot = $(spot).appendTo(node.dots);
        }

        // 列分组
        if (params.slideRow > 1) {
            for (var i = 0; i < this.count; i++) {
                if (i % params.slideRow === 0) {
                    temp = $(viewGroup).appendTo(node.list)
                }
                node.item.eq(i).appendTo(temp);
            }
        }

        node.group = node.list.children();
        this.count = node.group.length;

        if (params.circular) {
            for (var j = 0; j < params.slideCol; j++) {
                var front = node.group.eq(j);
                var later = node.group.eq(this.count - 1 - j);
                front.clone().addClass('clone').appendTo(node.list);
                later.clone().addClass('clone').prependTo(node.list);
            }
        }

        node.group = node.list.children();
        this.count = node.group.length;

        this.setSize();
        this.autoplay();
        this.controls();
        this.indicate();
        this.mouseInto();
        this.addMarked(this.index);
    };
    proto.setSize = function () {
        var node = this.node;
        var params = this.params;
        var spaces = node.item.css("margin-right");
        var roller = {
            width: node.view.width(),
            height: node.view.height(),
        }
        
        // 计算间距
        if (params.slideSpace === 'auto') {
            this.itemSpace = Math.floor(parseInt(spaces));
        } else if (/\%/.test(params.slideSpace)) {
            var valid = parseFloat(params.slideSpace) / 100;
            var gapItem = Math.floor(roller.width * valid);
            this.itemSpace = gapItem;
        } else {
            this.itemSpace = parseInt(params.slideSpace);
        }

        // 计算尺寸
        this.wrapLong = (this.isAcross ? roller.width : roller.height);
        this.itemLong = Math.ceil((this.wrapLong + this.itemSpace) / params.slideCol);
        this.listLong = this.itemLong * this.count - this.itemSpace;
        this.moveLong = this.itemLong * params.slideCount;
        this.initScroll = params.circular ? this.itemLong * params.slideCol : 0;
        if (this.isAcross) {
            node.list.width(this.listLong);
            node.group.width(this.itemLong - this.itemSpace);
            node.group.css({ marginRight: this.itemSpace, float: 'left' })
            node.group.last().css({ marginRight: 0 })
        } else {
            node.list.height(this.listLong);
            node.group.height(this.itemLong - this.itemSpace);
            node.group.css({ marginBottom: this.itemSpace })
            node.group.last().css({ marginBottom: 0 })
        }
        node.list.addClass('running');
        if (this.count > params.slideCol) {
            this.isAcross ? node.view.scrollLeft(this.initScroll) : node.view.scrollTop(this.viewHight);
        }
    };
    proto.autoplay = function (run) {
        var that = this;
        var params = that.params;
        clearInterval(that.timer);

        if (params.autoplay) {
            that.timer = setInterval(function () {
                switch(params.direction) {
                    case 'top':
                        params.isRoller ? that.rollPrev() : that.slidePrev();
                        break;
                    case 'left':
                        params.isRoller ? that.rollPrev() : that.slidePrev();
                        break;
                    case 'right':
                        params.isRoller ? that.rollNext() : that.slideNext();
                        break;
                    case 'bottom':
                        params.isRoller ? that.rollNext() : that.slideNext();
                        break;
                }
            }, params.isRoller ? 17 : params.interval);
        }
    };
    proto.haltPlay = function() {
        clearInterval(this.timer);
    }
    proto.controls = function () {
        var that = this;
        var node = that.node;
        var params = that.params;
        if (params.isRoller) return false;
        node.next && node.next.bind('click', function () {
            that.slidePrev()
        });
        node.prev.bind('click', function () {
            that.slideNext()
        });
    };

    // moving
    proto.rollPrev = function () {
        var node = this.node;
        var params = this.params;
        var rollLong = this.itemLong * (this.count - params.slideCol);
        var scrolled = this.isAcross ? node.view.scrollLeft() : node.view.scrollTop();

        if (scrolled < rollLong) {
            this.isAcross ? node.view.scrollLeft(++scrolled) : node.view.scrollTop(++scrolled);
        } else {
            this.isAcross ? node.view.scrollLeft(this.initScroll) : node.view.scrollTop(this.initScroll);
        }
    };
    proto.rollNext = function () {
        var node = this.node;
        var params = this.params;
        var rollLong = this.itemLong * (this.count - params.slideCol);

        var scrolled = this.isAcross ? node.view.scrollLeft() : node.view.scrollTop();
        if (scrolled > this.initScroll) {
            this.isAcross ? node.view.scrollLeft(--scrolled) : node.view.scrollTop(--scrolled);
        } else {
            this.isAcross ? node.view.scrollLeft(rollLong) : node.view.scrollTop(rollLong);
        }
    };
    proto.slidePrev = function () {
        var node = this.node;
        var params = this.params;
        var groups = params.circular ? this.count - 2 * params.slideCol :  this.count;
        var numbers = Math.ceil(groups / params.slideCount);
        var turnLong = this.initScroll - this.itemLong * params.slideCount;

        if (node.view.is(':animated')) return false;
        if (this.index < numbers - 1) {
            node.view.animate({ scrollLeft: '+=' + this.moveLong }, params.duration);
            this.index = this.index + 1;
        } else {
            params.circular ? node.view.scrollLeft(turnLong).animate({
                scrollLeft: '+=' + this.moveLong
            }, params.duration) : node.view.animate({
                scrollLeft: 0
            }, params.duration);
            this.index = 0;
        }
        this.addMarked(this.index);
    };
    proto.slideNext = function () {
        var node = this.node;
        var params = this.params;
        var groups = params.circular ? this.count - 2 * params.slideCol :  this.count;
        var numbers = Math.ceil(groups / params.slideCount);
        var turnLong = this.itemLong * (this.count - params.slideCount);
        if (node.view.is(':animated')) return false;
        if (this.index > 0) {
            node.view.animate({ scrollLeft: '-=' + this.moveLong }, params.duration);
            this.index = this.index - 1;
        } else {
            params.circular ? node.view.scrollLeft(turnLong).animate({
                scrollLeft: '-=' + this.moveLong
            }, params.duration) : node.view.animate({
                scrollLeft: turnLong
            }, params.duration);
            this.index = numbers - 1;
        }
        this.addMarked(this.index);
    };
    proto.indicate = function () {
        var that = this;
        var node = that.node;
        var params = that.params;
        var isAcross = (params.direction === 'left' || params.direction === 'right')
        if (params.isRoller) return false;

        node.spot && node.spot.bind(params.dotsEvent, function () {
            var index = $(this).index();
            var minus = index - that.index;
            var moved = that.itemLong * params.slideCount * minus;
            if (node.view.is(':animated')) return false;
            if (isAcross) {
                node.view.animate({ scrollLeft: '+=' + moved }, params.duration);
            } else {
                node.view.animate({ scrollTop: '+=' + moved }, params.duration);
            }
            that.index = index;
            that.addMarked(that.index);
        });
    };
    proto.tapdItem = function () {
        var that = this;
        var node = that.node;
        var params = that.params;

        node.item.bind('click', function () {
            var minus = 0;
            var count = 0;
            var index = 0;
            var offset = 0;
            var initAddr = 0;

            if (params.circular) {
                index = $(this).index() - params.slideCol;
                count = that.count - params.slideCol * 2;
                if (index < count) {
                    minus = index - that.index;
                    node.view.stop().animate({
                        scrollLeft: '+=' + that.cellWidth * minus
                    }, params.duration);
                    that.index = index;
                } else {
                    minus = index - that.index;
                    offset = count - that.index;
                    that.index = index - count;
                    initAddr = that.cellWidth * (params.slideCol - offset);
                    node.view.stop().scrollLeft(initAddr).animate({
                        scrollLeft: '+=' + that.cellWidth * minus
                    }, params.duration);
                }
            }
            that.addMarked(that.index);
        });
    };

    proto.resized = function () {
        var that = this;
        var node = that.node;
        var params = that.params;
        var isAcross = (params.direction === 'left' || params.direction === 'right')
        var EventUtil = that.EventUtil;

        EventUtil.bind(window, 'resize', function () {
            setTimeout(function () {
                that.wrapLong = (isAcross ? node.view.width() : node.view.height());
                that.setSize();
            }, 100)
        })
    };
    proto.mouseInto = function () {
        var that = this;
        var node = that.node;
        var params = that.params;
        if (!params.hoverStop) return false;
        node.elem.bind('mouseenter', function () { clearInterval(that.timer) });
        node.elem.bind('mouseleave', function () { that.autoplay() });
        node.prev && node.prev.bind('mouseenter', function () { clearInterval(that.timer) });
        node.prev && node.prev.bind('mouseleave', function () { that.autoplay() });
        node.next && node.next.bind('mouseenter', function () { clearInterval(that.timer) });
        node.next && node.next.bind('mouseleave', function () { that.autoplay() });
        node.spot && node.spot.bind('mouseenter', function () { clearInterval(that.timer) });
        node.spot && node.spot.bind('mouseleave', function () { that.autoplay() });
    }
    proto.addMarked = function (index) {
        var that = this;
        var node = that.node;
        var params = that.params;
        var activeIndex = params.slideCount * index;

        if (params.circular) {
            activeIndex = activeIndex + params.slideCol;
        }

        if (node.item) {
            node.item.removeClass(params.itemActive);
            for (var i = 0; i < params.slideCount; i++) {
                activeIndex = activeIndex + i;
                node.item.eq(activeIndex).addClass(params.itemActive);
            }
        }

        if (node.spot) {
            node.spot.removeClass(params.spotActive);
            node.spot.eq(index).addClass(params.spotActive);
        }
    }

    // 事件绑定方法
    proto.EventUtil = {
        bind: function (element, type, handler) {
            if (element.addEventListener) {
                element.addEventListener(type, handler, false);
            } else if (element.attachEvent) {
                element.attachEvent("on" + type, handler);
            } else {
                element["on" + type] = handler;
            }
        },
        remove: function (element, type, handler) {
            if (element.removeEventListener) {
                element.removeEventListener(type, handler, false);
            } else if (element.detachEvent) {
                element.detachEvent("on" + type, handler);
            } else {
                element["on" + type] = null;
            }
        }
    };
});