;(function ($, undefined) {

  // 绑定轮播对象到jQuery
  $.fn.ifocus = function (options) {
    if (!this.length) return false;
    return this.each(function () {
      var $element = $(this);
      if ($.type(options) !== 'string') {
        instance = new Focus($element, options);
        instance.init();
      }
    });
  };

  // 构造一个焦点图对象
  var Focus = function ($element, options) {
    this.ops = $.extend({}, this.defaults, options);
    this.obj = { elem: $element };
    this.num = 0;
    this.width = 0;
    this.index = 0;
    this.timer = null;
  };

  //定义对象的原型
  var _proto = Focus.prototype;

  //定义默认参数
  _proto.defaults = {
    speed: 700,
    duration: 4500,
    autoplay: true,
    hoverStop: false,
    hasSpotNum: false,
    layout: 'auto', // auto为100%，高度自动变化，fixed为高度不变，横向自动居中
    effect: 'slide', // fade为淡入 slide为滚动
    btnPrev: '.ifocus-prev', // 左边切换按钮
    btnNext: '.ifocus-next', // 右边切换按钮
    bunSpot: '.ifocus-spot', // 下标切换按钮
    imageSize: { w: 1920, h: 829 // 图片的尺寸
    },
    resize: function() {

    }
  };

  // 初始化所有方法
  _proto.init = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;

    the.getElem();
    the.setSize();
    the.autoplay();
    the.btnSwitch();
    the.bunSwitch();
    the.hoverStop();
    ops.resize(obj, ops);
    window.addEventListener('resize', function() {
      the.setSize();
      ops.resize(obj, ops);
    }, false)
  };

  //  获取元素
  _proto.getElem = function () {
    var obj = this.obj;
    var ops = this.ops;
    var name = 'ifocus-' + ops.effect;

    obj.list = obj.elem.find('.ifocus-list');
    obj.item = obj.elem.find('.ifocus-item');
    this.num = obj.item.length;
    obj.list.addClass(name);

    // 只有一项的时候返回
    if (this.len <= 1) {
      return false;
    }

    // 淡隐淡入时初始化
    if (ops.effect === 'fade') {
      obj.item.hide();
      obj.item.eq(0).addClass('active');
      obj.item.eq(0).show().css({ zIndex: 2 });
      obj.item.eq(this.num - 2).show().css({ zIndex: 1 });
    }

    // 滚动切换时初始化
    if (ops.effect === 'slide') {
      obj.item.last().clone().addClass('clone').prependTo(obj.list);
      obj.item.first().clone().addClass('clone').appendTo(obj.list);
      obj.item = obj.list.children();
      obj.item.eq(1).addClass('active');
      this.num = obj.item.length;
    }

    // 布局为fixed的时候
    if (ops.layout === 'fixed') {
      obj.view = $('<div class="ifocus-view"></div>');
      obj.view.prependTo(obj.elem);
      obj.list.appendTo(obj.view);
    }
  };

  // 布局初始化
  _proto.setSize = function () {
    var rate = 0;
    var obj = this.obj;
    var ops = this.ops;

    if (ops.layout === 'auto') {
      this.width = obj.elem.width();
      rate = Math.round(ops.imageSize.h / ops.imageSize.w * 1000000) / 10000 + '%';
      obj.elem.height('auto').css({ paddingBottom: rate });
    }

    if (ops.layout === 'fixed') {
      this.width = ops.imageSize.w;
      obj.elem.height(ops.imageSize.h);
      obj.view.width(ops.imageSize.w).height(ops.imageSize.h).css({
        position: 'absolute',
        top: 0,
        left: '50%',
        marginLeft: -ops.imageSize.w / 2
      });
    }

    if (ops.effect === 'slide') {
      obj.item.width(this.width);
      obj.list.width(this.width * this.num).css({ left: -this.width });
    }
  };

  // 淡隐淡入效果
  _proto.setFade = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;

    if (!obj.item.is(':animated')) {
      obj.item.eq(the.index).css({
        display: 'none',
        zIndex: 2
      }).stop().fadeIn(ops.speed, function () {
        obj.item.css('zIndex', 1).eq(the.index).show().siblings().hide();
      });
      the.addMark(the.index);
    }
  };

  // 向左滚动切换效果
  _proto.setPrev = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;
    var iLeft = -the.width * (the.num - 1);

    if (!obj.list.is(":animated")) {
      if (the.index > 0) {
        the.index = the.index - 1;
        obj.list.animate({ left: '+=' + the.width }, ops.speed);
      } else {
        the.index = the.num - 3;
        obj.list.css({ left: iLeft }).animate({ left: '+=' + the.width }, ops.speed);
      }
      the.addMark(the.index);
    }
  };

  // 向右滚动切换效果
  _proto.setNext = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;

    if (!obj.list.is(':animated')) {
      if (the.index < the.num - 3) {
        the.index = the.index + 1;
        obj.list.animate({ left: '-=' + the.width }, ops.speed);
      } else {
        the.index = 0;
        obj.list.css({ left: 0 }).animate({ left: '-=' + the.width }, ops.speed);
      }
      the.addMark(the.index);
    }
  };

  // 自动切换效果
  _proto.autoplay = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;

    if (ops.autoplay) {
      the.timer = setInterval(function () {
        if (ops.effect === 'fade') {
          the.index = the.index < the.num - 1 ? the.index + 1 : 0;
          the.setFade();
        };
        if (ops.effect === 'slide') the.setNext();
        // the.addMark(the.index);
      }, ops.duration);
    }
  };

  // 鼠标悬浮暂停
  _proto.hoverStop = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;
    if (ops.hoverStop) {
      obj.elem.hover(function () {
        clearInterval(the.timer);
      }, function () {
        the.autoplay();
      });
    }
  };

  // 效果切换按钮绑定事件
  _proto.btnSwitch = function () {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;

    obj.prev = obj.elem.find(ops.btnPrev);
    obj.next = obj.elem.find(ops.btnNext);

    obj.prev.hover(function () {
      clearInterval(the.timer);
    }, function () {
      the.autoplay();
    });

    obj.next.hover(function () {
      clearInterval(the.timer);
    }, function () {
      the.autoplay();
    });

    // 向左切换
    obj.prev.bind('click', function () {
      if (ops.effect === 'fade') {
        the.index = the.index > 0 ? the.index - 1 : the.num - 1;
        the.setFade();
      };
      if (ops.effect === 'slide') the.setPrev();
    });

    // 向右切换
    obj.next.bind('click', function () {
      if (ops.effect === 'fade') {
        the.index = the.index < the.num - 1 ? the.index + 1 : 0;
        the.setFade();
      };
      if (ops.effect === 'slide') the.setNext();
    });
  };

  _proto.bunSwitch = function () {
    var len = 0;
    var str = '';
    var the = this;
    var obj = the.obj;
    var ops = the.ops;
    var cla = '';

    obj.spot = obj.elem.find(ops.bunSpot);
    obj.spot = obj.spot.length > 0 ? obj.spot : $(ops.bunSpot);

    if (obj.spot.length < 1) {
      return false;
    }
    if (ops.effect === 'fade') {
      len = the.num;
    }
    if (ops.effect === 'slide') {
      len = the.num - 2;
    }

    for (var i = 0; i < len; i++) {
      if (ops.hasSpotNum) {
        str = str + '<span>' + (i + 1) + '</span>';
      } else {
        str = str + '<span></span>';
      }
    }

    cla = ops.bunSpot.replace('.', '') + '-nums';
    obj.spot.html(str);
    ops.hasSpotNum && obj.spot.addClass(cla);
    obj.ibun = obj.spot.children();
    obj.ibun.eq(0).addClass('active');
    obj.ibun.bind('click', function (event) {
      var index = $(this).index();
      var minus = index - the.index;

      event.preventDefault();
      if (!obj.list.is(":animated")) {
        the.index = index;
        if (ops.effect === 'fade') {
          the.setFade();
        };
        if (ops.effect === 'slide') {
          obj.list.animate({ left: '-=' + the.width * minus }, ops.speed);
        }
      }
      the.addMark(the.index);
    });
  };

  // 当前项增加标识class
  _proto.addMark = function (index) {
    var the = this;
    var obj = the.obj;
    var ops = the.ops;

    ops.effect === 'fade' ? the.c(obj.item, index) : the.c(obj.item, index + 1);
    obj.ibun && the.c(obj.ibun, index);
  };

  // 当前添加class 其他项移除class
  _proto.c = function (node, index) {
    node.eq(index).addClass('active').siblings().removeClass('active');
  };
})(jQuery);