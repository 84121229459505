var bdMap = {
  init: function (selector) {
    var that = this;
    that.getDetail(selector, function (id, locate) {
      that.setCoordinate(id, locate)
    }) 
  },
  transfer: function(str) {
    var objStr = str.replace('{', '').replace('}', '');
    var locate = {};
    objStr.split(/\s*\,\s*/).forEach(function(item) {
      var valArr = item.split(/\s*\:\s*/);
      locate[valArr[0]] = parseFloat(valArr[1]);
    })

    return locate;
  },
  getDetail: function (selector, func) {
    var mapItems = document.querySelectorAll(selector);
    var length = mapItems.length;
    for (var i = 0; i < length; i++) {
      var locate = mapItems[i].getAttribute('data-coordinate');
      func && func(mapItems[i], this.transfer(locate))
    }
  },
  setCoordinate: function (id, locate) {
    var map = new BMap.Map(id);
    var point = new BMap.Point(locate.lat, locate.lng);
    var icon = new BMap.Icon("/resource/images/location.png", new BMap.Size(42,50));
    var marker = new BMap.Marker(point,{icon:icon});  // 创建标

    map.centerAndZoom(point, 15);
    // map.setMapStyle({style:'dark'});
    map.addControl(new BMap.MapTypeControl());
    map.addOverlay(marker);
    marker.setAnimation(BMAP_ANIMATION_BOUNCE);
    map.enableScrollWheelZoom(true);
  }
}