(function (a, b) {
  if (typeof define === 'function' && define.amd) {
      define(['jquery'], b);
  } else {
      a.b = b(jQuery)
  }
})(typeof window !== undefined ? window : this, function ($) {
  $.fn.extend({
      topnav: function (settings) {
          if (this.length < 1) return false;

          var Segment = function ($element, settings) {
              this.node = {
                  elem: $element
              };
              this.index = 0;
              this.params = $.extend({}, this.defaults, settings);
          }

          var proto = Segment.prototype;

          // 默认参数
          proto.defaults = {
              lineWrap: true,
              itemSpace: 10,
              isTarget: false,
              classActive: 'active',
              classButton: '.segment-ibtn',
              classTabcon: '.segment-item',
              classTabbar: '.segment-menu',
              afterLeave: function () {}
          }

          // 初始化
          proto.initial = function () {
              var that = this
              var node = that.node;
              var params = that.params;
              node.wrap = node.elem.find(params.classTabbar)
              node.ibtn = node.elem.find(params.classButton)
              node.item = node.elem.find(params.classTabcon)
              that.href = node.ibtn.eq(0).attr('href') ? node.ibtn.eq(0).attr('href') : node.ibtn.eq(0).data('href');

              !params.lineWrap && this.createView()
              if (!that.href || /(\#|javascript)/ig.test(that.href)) {
                  that.tabbar()
              } else {
                  that.action()
              }
              node.ibtn.bind('click', function (event) {
                  event.preventDefault()
                  that.href = $(this).attr('href') ? $(this).attr('href') : $(this).data('href');
                  that.index = $(this).index()
                  if (!that.href || /(\#|javascript)/ig.test(that.href)) {
                      that.tabbar()
                  } else {
                      params.isTarget ? window.open(that.href, '_blank') : window.location.href = that.href
                  }
              })
          }

          proto.tabbar = function () {
              var node = this.node;
              var params = this.params;
              node.item.eq(this.index).fadeIn(300).siblings().hide();
              node.ibtn.eq(this.index).addClass(params.classActive).siblings().removeClass(params.classActive);
              node.item.eq(this.index).addClass(params.classActive).siblings().removeClass(params.classActive);
              params.afterLeave(node, this.index)   
          }

          proto.action = function () {
              var node = this.node;
              var params = this.params;
              var current = node.wrap.find(params.classButton + '.' + params.classActive);
              var wrapWidth = node.wrap.width();
              var currentOffset = current.offset().left - wrapWidth / 2
              node.wrap.scrollLeft(currentOffset)
              setTimeout(function () {
                  node.elem.addClass('show').removeClass('hide')
              }, 200)
          }

          proto.createView = function name(params) {
              var that = this;
              var node = that.node;
              var params = that.params;
              var moving = node.wrap.width();
              var totalLong = 0;
              node.elem.addClass('hide')
              node.view = $('<div class="segment-view"></div>').appendTo(node.wrap)
              node.next = $('<div class="segment-next"></div>')
              node.prev = $('<div class="segment-prev"></div>')
              node.wrap.after(node.next)
              node.wrap.after(node.prev)
              node.ibtn.appendTo(node.view)
              node.ibtn.each(function () {
                  totalLong += Math.ceil($(this).outerWidth()) + params.itemSpace * 2
              })
              node.view.width(totalLong)
              that.addMark();
              node.next.bind('click', function () {
                  node.wrap.animate({scrollLeft: '-=' +moving }, 400)
                  that.addMark();
              })

              node.prev.bind('click', function () {
                  node.wrap.animate({scrollLeft: '+=' +moving }, 400)
                  that.addMark();
              })
          }

          proto.addMark = function () {
              var node = this.node;
              var width = node.view.width();
              var moving = node.wrap.width();
              var scrollleft = node.wrap.scrollLeft();
              if (scrollleft) {
                  node.next.addClass('disabled')
              }
              if (scrollleft >= width - moving) {
                  node.prev.addClass('disabled')
              }
          }


          return this.each(function () {
              var $self = $(this);
              if ($.type(settings) !== "string") {
                  instance = new Segment($self, settings);
                  instance.initial();
              }
          })
      },
  })
});